import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import {
  FilterComponent, Whitebutton,
  Span,
} from './styles';
import MultiSelect from '../Input/MultiSelect';
import Select from '../Input/Select';
import DoubleRangeSlider from '../Input/DoubleRangeSlider';
import Button from '../Button/Button';
import { Label } from '../Input/styles';
import Constants from '../../shared/constants';
import CustomDatePicker from '../CustomCalender/CustomDatePicker';
import { GreyCard, WhiteCard } from '../../pages/gigger/styles';
import {
  getLocaleFromURL, getLanguages, getCities, isCustomer, getWorkAvailabilityOptions, formatDate,
} from '../../shared/utils';
import CheckBox from '../Input/CustomCheckbox';
import Colors from '../../styles/Colors';

class Filter extends React.Component {
  currentLanguage = getLocaleFromURL();

  constructor(props) {
    super(props);
    this.state = {
      filterData: Constants.defaultFilterData,
      jobTitle: props.jobTitle || [],
      categoryData: props.categoryData || [],
      skillsData: props.skillsData || [],
      systemsData: props.systemsData || [],
      experienceOptions: Constants.options.experienceOptions,
      cityOptions: getCities(),
      languageOptions: getLanguages(),
      workAvailability: getWorkAvailabilityOptions(),
      additionalDateOptions: Constants.options.additionalDateOptions,
      startDate: '',
      endDate: '',
      isFlexible: false,
      sliderInputMin: Constants.slider.min,
      sliderInputMax: Constants.slider.max,
      addDates: '',
      formvalid: {
        startDate: '',
        endDate: '',
      },
      gradesData: props.gradesData || [],
    };
  }

  componentDidMount() {
    const { data } = this.props;
    if (data && data.length) {
      this.setState({ filterData: data });
    }
  }

componentDidUpdate=(prevProps, prevState) => {
  const { data, updateFilterData = false } = this.props;
  const { filterData = [] } = data;
  const { filterData: stateFilterData } = this.state;
  if (prevProps.data !== data && filterData.length > 1 && updateFilterData) {
    this.handleUpdateFilterData(data);
    this.clearSingleFilterOptions();
  } else if (prevState.filterData !== stateFilterData) {
    this.filter(stateFilterData);
  }
  if (filterData.length === 0) {
    this.clearFilterOptions();
  }
}

/**
 * Updates the input values of filter by collecting data from parent.
 * @param {object} data filter data
 */
handleUpdateFilterData=(data) => {
  const { filterData, stateData = {} } = data;
  const {
    jobTitle,
    categoryData,
    skillsData,
    systemsData,
    experienceOptions = Constants.options.experienceOptions.map(
      (obj) => ({ ...obj, isSelected: false }),
    ),
    additionalDateOptions = Constants.options.additionalDateOptions.map(
      (obj) => ({ ...obj, isSelected: false }),
    ),
    cityOptions = getCities(),
    languageOptions = getLanguages(),
    workAvailability = getWorkAvailabilityOptions(),
    startDate = '',
    endDate = '',
    isFlexible = false,
    addDates = '',
    sliderInputMin = Constants.slider.min,
    sliderInputMax = Constants.slider.max,
    gradesData,
  } = stateData;
  this.setState({
    filterData,
    jobTitle,
    categoryData,
    skillsData,
    systemsData,
    experienceOptions,
    additionalDateOptions,
    cityOptions,
    languageOptions,
    workAvailability,
    startDate,
    endDate,
    isFlexible,
    addDates,
    sliderInputMin,
    sliderInputMax,
    gradesData,
  });
}

static getDerivedStateFromProps(nextProps, state) {
  const {
    jobTitle, categoryData, skillsData, systemsData, gradesData,
  } = state;
  if (
    nextProps.jobTitle !== jobTitle
      || nextProps.categoryData !== categoryData
      || nextProps.skillsData !== skillsData
      || nextProps.systemsData !== systemsData
      || nextProps.gradesData !== gradesData
  ) {
    return ({
      jobTitle: nextProps.jobTitle,
      categoryData: nextProps.categoryData,
      skillsData: nextProps.skillsData,
      systemsData: nextProps.systemsData,
      gradesData: nextProps.gradesData,
    });
  }
  return null;
}

clearFilterOptions = () => {
  this.setState({
    filterData: Constants.defaultFilterData,
  });
}

clearSingleFilterOptions = () => {
  const { data: { filterData = [] } } = this.props;
  const { filterData: stateFilterData } = this.state;
  const excludedFilters = [
    'job_title', 'category', 'systems', 'skills',
  ];
  const updatedFilterData = filterData.find(
    (obj) => !excludedFilters.includes(obj.name)
      && obj !== stateFilterData.find((item) => item.name === obj.name),
  );
  if (updatedFilterData && updatedFilterData.name) {
    switch (updatedFilterData.name) {
      case 'language':
        this.handlelanguageFliterChange(updatedFilterData);
        break;
      case 'rate':
        this.handleRateFliterChange(updatedFilterData);
        break;
      case 'cities':
        this.handleCitiesFliterChange(updatedFilterData);
        break;
      case 'availabledate':
        this.handleAvailabledateFliterChange();
        break;
      case 'experience':
        this.handleExperienceFliterChange(updatedFilterData);
        break;
      case 'work_availability':
        this.handleWorkAvailabilityFliterChange(updatedFilterData);
        break;
      default:
        break;
    }
    this.setState({ filterData });
  }
}

handlelanguageFliterChange = (updatedFilterData) => {
  const { languageOptions: language } = this.state;
  this.setState({
    languageOptions: language.map(
      (obj) => ({ ...obj, isSelected: updatedFilterData.label.includes(obj.value) }),
    ),
  });
}

handleCitiesFliterChange = (updatedFilterData) => {
  const { cityOptions: cities } = this.state;
  this.setState({
    cityOptions: cities.map(
      (obj) => ({ ...obj, isSelected: updatedFilterData.label.includes(obj.label) }),
    ),
  });
}

handleExperienceFliterChange = (updatedFilterData = '') => {
  const { experienceOptions: experience } = this.state;
  const { label = '' } = updatedFilterData;
  this.setState({
    experienceOptions: experience.map(
      (obj) => ({ ...obj, isSelected: label.includes(obj.value) }),
    ),
  });
}

handleWorkAvailabilityFliterChange = (updatedFilterData) => {
  const { workAvailability: cities } = this.state;
  this.setState({
    workAvailability: cities.map(
      (obj) => ({ ...obj, isSelected: updatedFilterData.label.includes(obj.value) }),
    ),
  });
}

  handleAvailabledateFliterChange = () => {
    const { additionalDateOptions } = this.state;
    this.setState({
      startDate: '',
      endDate: '',
      addDates: '',
      additionalDateOptions: additionalDateOptions.map((o) => ({ ...o, isSelected: false })),
    });
  }

handleRateFliterChange = () => {
  this.setState({
    sliderInputMin: Constants.slider.min,
    sliderInputMax: Constants.slider.max,
  });
}

/**
 * Handles change in input element value
 * @param {string} name Name of the input element
 * @param {string} label Label of the input element
 * @param {string} value Value of the input element
 */
  handleChange = (name, label, value) => {
    let { filterData } = this.state;
    filterData = filterData.map((obj) => {
      if (obj.name === name) {
        return ((name === Constants.localStorageKeys.language)
          || (name === Constants.workAvailability))
          ? { ...obj, id: value, label: value } : name === Constants.Experience ? { ...obj, id: value, label: value.replace(/\s/g, '').split(',').join(', ') } : { ...obj, id: label, label };
      }
      return obj;
    });
    this.setState({ filterData });
  };

  /**
   * @function filter
   * @callback onApply
   * applies all the entered filters.
   */
  filter = () => {
    let { filterData } = this.state;
    const {
      jobTitle,
      categoryData,
      skillsData,
      systemsData,
      experienceOptions,
      cityOptions,
      languageOptions,
      isFlexible,
      sliderInputMin,
      sliderInputMax,
      addDates,
      gradesData,
    } = this.state;
    let {
      startDate,
      endDate,
    } = this.state;
    const stateData = {
      jobTitle,
      categoryData,
      skillsData,
      systemsData,
      experienceOptions,
      cityOptions,
      languageOptions,
      startDate,
      endDate,
      isFlexible,
      sliderInputMin,
      sliderInputMax,
      addDates,
      gradesData,
    };
    if (addDates && (startDate || endDate)) {
      if (startDate) {
        startDate = new Date(startDate);
        const lastWeek = startDate.setDate(startDate.getDate() + (addDates * 7));
        startDate = moment(lastWeek).format(Constants.yearMonthDateFormat);
      }

      if (endDate) {
        endDate = new Date(endDate);
        const nextWeek = endDate.setDate(endDate.getDate() - (addDates * 7));
        endDate = moment(nextWeek).format(Constants.yearMonthDateFormat);
      }

      filterData = filterData.map((obj) => {
        if (obj.name === 'availabledate') {
          return { ...obj, id: `${startDate},${endDate}`, label: 'available_date' };
        }
        return obj;
      });
    }
    const { onApply, cancelFilterDataUpdation } = this.props;
    onApply(filterData, stateData);
    if (cancelFilterDataUpdation) {
      cancelFilterDataUpdation();
    }
  };

  /**
   * Handles change in slider
   * @param {number} min Minimum slider value
   * @param {number} max Maximum slider value
   */
  sliderChange = (min, max) => {
    let { filterData } = this.state;
    filterData = filterData.map((obj) => {
      if (obj.name === 'rate') {
        return { ...obj, id: `${min},${max}`, label: 'hourly_rate' };
      }
      return obj;
    });
    this.setState({ filterData, sliderInputMin: min, sliderInputMax: max });
  };

  /**
   * handles change in date value
   * @param {string} type type of the date element
   */
  handleDateChange = (type) => (name, value) => {
    let { filterData, startDate, endDate } = this.state;
    const { INVALID_DATE } = Constants;
    startDate = type === 'start' ? value : startDate;
    endDate = type === 'end' ? value : endDate;

    filterData = filterData.map((obj) => {
      if (obj.name === 'availabledate' && moment(value).format(Constants.yearMonthDateFormat) !== INVALID_DATE) {
        const date = startDate && endDate ? `${startDate},${endDate}` : startDate || (endDate ? `,${endDate}` : '');
        return { ...obj, id: date, label: 'available_date' };
      }
      return obj;
    });

    if (moment(value).format(Constants.yearMonthDateFormat) !== INVALID_DATE) {
      this.setState({ filterData, startDate, endDate });
    }
  };

  /**
   * @function clearFilter
   * @callback onClearFilters
   * Clears all the applied filters.
   */
  clearFilter = () => {
    const {
      jobTitle, categoryData, skillsData, onClearFilters, systemsData, gradesData,
    } = this.props;
    const {
      languageOptions, cityOptions, additionalDateOptions, experienceOptions,
    } = this.state;
    this.setState({
      filterData: Constants.defaultFilterData,
      jobTitle: jobTitle.map((obj) => ({ ...obj, isSelected: false })),
      categoryData: categoryData.map((obj) => ({ ...obj, isSelected: false })),
      skillsData: skillsData.map((obj) => ({ ...obj, isSelected: false })),
      systemsData: systemsData.map((obj) => ({ ...obj, isSelected: false })),
      experienceOptions: experienceOptions.map((obj) => ({ ...obj, isSelected: false })),
      cityOptions: cityOptions.map((obj) => ({ ...obj, isSelected: false })),
      languageOptions: languageOptions.map((obj) => ({ ...obj, isSelected: false })),
      additionalDateOptions: additionalDateOptions.map((obj) => ({ ...obj, isSelected: false })),
      startDate: '',
      endDate: '',
      isFlexible: false,
      sliderInputMin: Constants.slider.min,
      sliderInputMax: Constants.slider.max,
      addDates: '',
      workAvailability: getWorkAvailabilityOptions().map((obj) => ({ ...obj, isSelected: false })),
      gradesData: gradesData.map((obj) => ({ ...obj, isSelected: false })),
    }, () => onClearFilters());
  };

  /**
   * Handles change in checking in and out of im flexible button.
   */
   handleIsFlexibleButton = () => {
     const {
       isFlexible, additionalDateOptions, startDate = '', endDate = '',
     } = this.state;
     let { filterData } = this.state;
     filterData = filterData.map((obj) => {
       if (obj.name === Constants.AVAILABLEDATE_FILTER_NAME) {
         return {
           ...obj,
           id: (isFlexible && startDate && endDate) ? `${startDate},${endDate}` : '',
           label: isFlexible ? 'available_date' : '',
           isFlexible,
         };
       }
       return obj;
     });
     const dateOptions = additionalDateOptions.map((o) => ({ ...o, isSelected: false }));
     this.setState({
       isFlexible: !isFlexible,
       filterData,
       addDates: '',
       additionalDateOptions: dateOptions,
     });
   };

  /**
   * Handles on key press method of date type of input element.
   * @param {event} evt
   */
  handleKeypress=(evt) => {
    const theEvent = evt || window.event;
    const { name } = theEvent.target;
    const { value } = theEvent.target;
    if ((name === Constants.from || name === Constants.to)
    && value.length >= Constants.dateLength) {
      theEvent.preventDefault();
    }
  }

  /**
   * Handles change in selecting plus or minus dates option.
   * @param {string} name Name of the input element
   * @param {string} label Label of the input element
   * @param {string} value Value of the input element
   */
  handlePlusOrMinusDates=(name, label, value) => {
    let { startDate, endDate } = this.state;
    const { filterData } = this.state;
    if (startDate) {
      startDate = new Date(startDate);
      const lastWeek = startDate.setDate(startDate.getDate() + (value * 7));
      startDate = formatDate(lastWeek, Constants.yearMonthDateFormat);
    }

    if (endDate) {
      endDate = new Date(endDate);
      const nextWeek = endDate.setDate(endDate.getDate() - (value * 7));
      endDate = formatDate(nextWeek, Constants.yearMonthDateFormat);
    }
    const updatedFilterData = filterData.map((obj) => {
      if (obj.name === 'availabledate') {
        return { ...obj, id: `${startDate}, ${endDate}` };
      }
      return obj;
    });
    this.setState({
      [name]: value,
      filterData: updatedFilterData,
    });
  }

  render() {
    const {
      jobTitle,
      categoryData,
      skillsData,
      systemsData,
      experienceOptions,
      cityOptions,
      languageOptions,
      isFlexible,
      endDate,
      startDate,
      sliderInputMax,
      sliderInputMin,
      additionalDateOptions,
      addDates,
      workAvailability,
      formvalid,
      gradesData,
    } = this.state;
    const { mobileFilter, isAdmin = false, disableJobTitle } = this.props;
    return (
      <GreyCard>
        <FilterComponent className={isAdmin && 'mt-4'}>
          <div className="row m-0">
            <form className="p-3 overflow-hidden" autoComplete="off" onSubmit={(e) => { e.preventDefault(); }}>
              <MultiSelect
                forFilter
                showSearch
                className="mb-4"
                label={<FormattedMessage id="common_title" defaultMessage="Title" />}
                placeholder={<FormattedMessage id="gigger_filter_choose_titles" defaultMessage="Choose titles" />}
                name="job_title"
                options={jobTitle}
                onChange={this.handleChange}
                onKeyPress={this.handleKeypress}
                noEmptyError
                disabled={disableJobTitle}
              />
              <MultiSelect
                forFilter
                showSearch
                className="mb-4"
                label={<FormattedMessage id="common_category" defaultMessage="Category" />}
                name="category"
                placeholder={<FormattedMessage id="gigger_filter_choose_category" defaultMessage="Choose category" />}
                options={categoryData}
                onChange={this.handleChange}
                onKeyPress={this.handleKeypress}
                noEmptyError
              />
              <MultiSelect
                forFilter
                showSearch
                className="mb-4"
                label={<FormattedMessage id="common_systems" defaultMessage="Systems" />}
                name="systems"
                placeholder={<FormattedMessage id="gigger_filter_choose_systems" defaultMessage="Choose Systems" />}
                options={systemsData}
                onChange={this.handleChange}
                onKeyPress={this.handleKeypress}
                noEmptyError
              />
              <MultiSelect
                forFilter
                showSearch
                className="mb-4"
                label={<FormattedMessage id="common_skill" defaultMessage="Skill" />}
                name="skills"
                placeholder={<FormattedMessage id="gigger_filter_choose_skills" defaultMessage="Choose skills" />}
                options={skillsData}
                onChange={this.handleChange}
                onKeyPress={this.handleKeypress}
                noEmptyError
              />
              <MultiSelect
                forFilter
                className="mb-4"
                label={<FormattedMessage id="gigger_filter_level_of_experience" defaultMessage="Level of experience" />}
                name="experience"
                placeholder={<FormattedMessage id="gigger_filter_choose_experience" defaultMessage="Choose experience" />}
                options={experienceOptions}
                onChange={this.handleChange}
                onKeyPress={this.handleKeypress}
                noEmptyError
              />
              <Label forFilter>
                <FormattedMessage id="common_availability" defaultMessage="Availability" />
              </Label>
              <CustomDatePicker
                className="mb-4"
                forFilter
                label={<FormattedMessage id="common_start" defaultMessage="Start" />}
                value={startDate}
                disabled={isFlexible}
                type="date"
                placeholder="YYYY-MM-DD"
                name="from"
                existError={endDate < startDate && endDate !== '' && !formvalid.endDate}
                existErrorMessage={(
                  <FormattedMessage
                    id="error_message_from_date_is_greater"
                    defaultMessage="From date shouldn't be greater than to date"
                  />
                )}
                onChange={this.handleDateChange('start')}
                noEmptyError
              />
              <CustomDatePicker
                className="mb-4"
                forFilter
                label={<FormattedMessage id="common_end" defaultMessage="End" />}
                type="date"
                placeholder="YYYY-MM-DD"
                value={endDate}
                disabled={isFlexible}
                name="to"
                onChange={this.handleDateChange('end')}
                errorMessage={<FormattedMessage id="error_end_date_message" defaultMessage="Enter end date" />}
                noEmptyError
              />
              <div className="row m-0 mb-3 color-black">
                <CheckBox
                  isSelected={isFlexible}
                  label={Constants.language.gigger_filter_i_am_flexible}
                  onClick={this.handleIsFlexibleButton}
                  bgcolor={Colors.Primary}
                />
              </div>
              {isCustomer() && (
              <div className="mb-4">
                <Select
                  forFilter
                  showSearch
                  name="addDates"
                  value={addDates !== '' ? Constants.translateWeek[addDates] : ''}
                  disabled={isFlexible || (startDate === '' && endDate === '')}
                  options={additionalDateOptions}
                  onChange={this.handlePlusOrMinusDates}
                  onKeyPress={this.handleKeypress}
                  noEmptyError
                />
              </div>
              )}
              <MultiSelect
                forFilter
                showSearch
                className="mb-4"
                label={<FormattedMessage id="common_location" defaultMessage="Location" />}
                name="cities"
                placeholder={<FormattedMessage id="gigger_filter_choose_locations" defaultMessage="Choose locations" />}
                options={cityOptions}
                onChange={this.handleChange}
                onKeyPress={this.handleKeypress}
                noEmptyError
              />
              <>
                <DoubleRangeSlider
                  forFilter
                  className="mb-4"
                  label={<FormattedMessage id="gigger_filter_hourly_rate" defaultMessage="Hourly Rate" />}
                  min={Constants.slider.min}
                  max={Constants.slider.max}
                  minValue={sliderInputMin}
                  maxValue={sliderInputMax}
                  sliderChange={this.sliderChange}
                  isPortal={isAdmin}
                  onChange={this.handleChange}
                />
              </>
              <MultiSelect
                forFilter
                showSearch
                className="mb-4"
                label={<FormattedMessage id="gigger_filter_language_knowledge" defaultMessage="Language knowledge" />}
                name="language"
                placeholder={<FormattedMessage id="gigger_filter_choose_language" defaultMessage="Choose language" />}
                options={languageOptions}
                onChange={this.handleChange}
                onKeyPress={this.handleKeypress}
                position={mobileFilter}
                noEmptyError
              />
              <MultiSelect
                forFilter
                className="mb-4"
                label={<FormattedMessage id="common_work_availability" defaultMessage="Work Availability" />}
                name={Constants.workAvailability}
                placeholder={<FormattedMessage id="gigger_filter_choose_work_availability" defaultMessage="Choose Work Availability" />}
                options={workAvailability}
                onChange={this.handleChange}
                onKeyPress={this.handleKeypress}
                noEmptyError
              />
              {isAdmin && (
              <MultiSelect
                forFilter
                className="mb-4"
                label={<FormattedMessage id="grade" defaultMessage="Grade" />}
                name={Constants.grade}
                placeholder={<FormattedMessage id="choose_grade" defaultMessage="Choose grade" />}
                options={gradesData}
                onChange={this.handleChange}
                onKeyPress={this.handleKeypress}
                noEmptyError
              />
              )}
              <Button
                name={<FormattedMessage id="gigger_filter_clear_all" defaultMessage="Clear all" />}
                onClick={this.clearFilter}
                type="cancel"
                className="d-lg-block d-xl-block d-none"
              />
            </form>
          </div>
          <WhiteCard className="fixed-bottom py-4 px-3 d-flex d-lg-none d-xlnone" border>
            <div className="col-5 p-0">
              <Whitebutton customWidth onClick={this.clearFilter} data-dismiss="modal" aria-label="Close">
                <Span className="color-black" font="14">
                  <u><FormattedMessage id="gigger_filter_clear_all" defaultMessage="Clear all" /></u>
                </Span>
              </Whitebutton>
            </div>
          </WhiteCard>
        </FilterComponent>
      </GreyCard>
    );
  }
}

export default Filter;
