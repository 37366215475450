import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Config from '../../../config';
import {
  CancelButton,
  Button,
  Input,
  Text,
} from '../../../components/Modals/styles';
import GroupsService from '../../../services/AdminServices/GroupsService';

const DeleteGroupModal = ({
  data, onModalClose, handleToast, setLoading,
}) => {
  const groupsService = useRef(new GroupsService(Config.apiBase));
  const [isDisabled, setIsDisabled] = useState(false);
  const closeModal = useRef(null);

  const handleSubmit = async () => {
    setIsDisabled(true);
    setLoading(true);
    const { id } = data;
    try {
      await groupsService.current.deleteGroup(id);
      onModalClose();
      handleToast({ type: 'success', message: 'Group deleted' });
    } catch (error) {
      onModalClose();
      setLoading(false);
      handleToast({ type: 'error', message: 'Failed to delete group' });
    }
  };

  return (
    <>
      <Text className="mt-2">
        <FormattedMessage id="message_group_deletion" defaultMessage="Are you sure you want to delete group?" />
      </Text>
      <Input className="mt-4">
        <Button
          disabled={isDisabled}
          onClick={handleSubmit}
          data-dismiss="modal"
          aria-label="Close"
        >
          <FormattedMessage id="delete_group" defaultMessage="Delete group" />
        </Button>
        <CancelButton
          data-dismiss="modal"
          aria-label="Close"
          onClick={onModalClose}
          ref={closeModal}
        >
          <FormattedMessage id="common_cancel" defaultMessage="Cancel" />
        </CancelButton>
      </Input>
    </>
  );
};

export default DeleteGroupModal;
